// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Random = require("rescript/lib/js/random.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function getOffset(element) {
  var elementObject = Belt_Option.map(element, (function (prim) {
          return prim;
        }));
  var _element = elementObject;
  var _x = 0;
  var _y = 0;
  while(true) {
    var y = _y;
    var x = _x;
    var element$1 = _element;
    if (element$1 === undefined) {
      return [
              x,
              y
            ];
    }
    var obj = Caml_option.valFromOption(element$1);
    _y = y - obj.offsetTop | 0;
    _x = x - obj.offsetLeft | 0;
    _element = Caml_option.nullable_to_opt(obj.offsetParent);
    continue ;
  };
}

function limit(value, min, max) {
  return Caml_obj.caml_min(max, Caml_obj.caml_max(min, value));
}

function randomArrayValue(array) {
  return Caml_array.get(array, Random.$$int(array.length));
}

function randomInt(min, max) {
  return min + Random.$$int((max - min | 0) + 1 | 0) | 0;
}

function randomFloat(min, max) {
  return min + Random.$$float(max - min);
}

function getArrayIndex(array, value, _index) {
  while(true) {
    var index = _index;
    if (Caml_array.get(array, index) === value) {
      return index;
    }
    _index = index + 1 | 0;
    continue ;
  };
}

exports.getOffset = getOffset;
exports.limit = limit;
exports.randomArrayValue = randomArrayValue;
exports.randomInt = randomInt;
exports.randomFloat = randomFloat;
exports.getArrayIndex = getArrayIndex;
/* No side effect */
