// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function create(length, initialValue) {
  return {
          length: length,
          buffer: Caml_array.make(length + 1 | 0, initialValue),
          indexHead: 0,
          indexTail: 0,
          indexAccess: 0
        };
}

function isEmpty(t) {
  return t.indexHead === t.indexTail;
}

function read(t) {
  if (isEmpty(t)) {
    return ;
  } else {
    return Caml_option.some(Caml_array.get(t.buffer, t.indexAccess));
  }
}

function pop(t) {
  if (isEmpty(t)) {
    return t;
  } else {
    return {
            length: t.length,
            buffer: t.buffer,
            indexHead: t.indexAccess,
            indexTail: t.indexTail,
            indexAccess: t.indexAccess === 0 ? t.length : t.indexAccess - 1 | 0
          };
  }
}

function incrementValue(value, max) {
  if (value === max) {
    return 0;
  } else {
    return value + 1 | 0;
  }
}

function write(value, t) {
  Caml_array.set(t.buffer, t.indexHead, value);
  var indexHead = incrementValue(t.indexHead, t.length);
  var indexTail = indexHead === t.indexTail ? incrementValue(t.indexTail, t.length) : t.indexTail;
  return {
          length: t.length,
          buffer: t.buffer,
          indexHead: indexHead,
          indexTail: indexTail,
          indexAccess: t.indexHead
        };
}

exports.create = create;
exports.isEmpty = isEmpty;
exports.read = read;
exports.pop = pop;
exports.write = write;
/* No side effect */
