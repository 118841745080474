// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils$ReasonmlSequencer = require("./Utils.bs.js");
var Timing$ReasonmlSequencer = require("./Timing.bs.js");
var SynthValues$ReasonmlSequencer = require("./SynthValues.bs.js");

function create(values) {
  if (SynthValues$ReasonmlSequencer.length(values) === 0) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "values length === 0",
          Error: new Error()
        };
  }
  return {
          values: values,
          loopLength: Caml.caml_int_max(1, SynthValues$ReasonmlSequencer.length(values) / 2 | 0),
          timing: Timing$ReasonmlSequencer.start
        };
}

function merge(incomingSynthInstance, existingSynthInstance) {
  return {
          values: incomingSynthInstance.values,
          loopLength: incomingSynthInstance.loopLength,
          timing: Timing$ReasonmlSequencer.merge(incomingSynthInstance.loopLength, incomingSynthInstance.timing, existingSynthInstance.timing)
        };
}

function restart(synthInstance) {
  return {
          values: synthInstance.values,
          loopLength: synthInstance.loopLength,
          timing: Timing$ReasonmlSequencer.restart(synthInstance.timing)
        };
}

function advance(sync, synthInstance) {
  return {
          values: synthInstance.values,
          loopLength: synthInstance.loopLength,
          timing: Timing$ReasonmlSequencer.advance(synthInstance.loopLength, sync, synthInstance.timing)
        };
}

function randomAbsolute(valueConverter, synthInstance) {
  return {
          values: Curry._1(valueConverter.randomValuesAbsolute, synthInstance.values),
          loopLength: Utils$ReasonmlSequencer.randomInt(2, SynthValues$ReasonmlSequencer.length(synthInstance.values)),
          timing: synthInstance.timing
        };
}

function randomRelative(valueConverter, synthInstance) {
  return {
          values: Curry._1(valueConverter.randomValuesRelative, synthInstance.values),
          loopLength: Utils$ReasonmlSequencer.randomInt(2, SynthValues$ReasonmlSequencer.length(synthInstance.values)),
          timing: synthInstance.timing
        };
}

function loopLength(synthInstance) {
  return synthInstance.loopLength;
}

function setLoopLength(loopLength, synthInstance) {
  if (loopLength <= 0) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "loop length <= 0",
          Error: new Error()
        };
  }
  if (loopLength >= SynthValues$ReasonmlSequencer.length(synthInstance.values)) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "loop length >= length of values",
          Error: new Error()
        };
  }
  return {
          values: synthInstance.values,
          loopLength: loopLength,
          timing: synthInstance.timing
        };
}

function reset(valueConverter, synthInstance) {
  return {
          values: Curry._1(valueConverter.defaultValues, SynthValues$ReasonmlSequencer.length(synthInstance.values)),
          loopLength: synthInstance.loopLength,
          timing: synthInstance.timing
        };
}

function setSubTicks(subTicks, synthInstance) {
  return {
          values: synthInstance.values,
          loopLength: synthInstance.loopLength,
          timing: Timing$ReasonmlSequencer.setSubTicks(subTicks, synthInstance.timing)
        };
}

function values(synthInstance) {
  return synthInstance.values;
}

function setValues(values, synthInstance) {
  return {
          values: values,
          loopLength: Caml.caml_int_min(synthInstance.loopLength, SynthValues$ReasonmlSequencer.length(values)),
          timing: synthInstance.timing
        };
}

function applyUpdate(valueConverter, values, update, synthInstance) {
  return {
          values: Curry._2(valueConverter.updateValues, values, update),
          loopLength: synthInstance.loopLength,
          timing: synthInstance.timing
        };
}

function timing(synthInstance) {
  return synthInstance.timing;
}

exports.create = create;
exports.merge = merge;
exports.restart = restart;
exports.advance = advance;
exports.randomAbsolute = randomAbsolute;
exports.randomRelative = randomRelative;
exports.loopLength = loopLength;
exports.setLoopLength = setLoopLength;
exports.reset = reset;
exports.setSubTicks = setSubTicks;
exports.values = values;
exports.setValues = setValues;
exports.applyUpdate = applyUpdate;
exports.timing = timing;
/* No side effect */
