// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function updateEditMode(targetId, targetValues, update, mouseAction, editMode) {
  var noOp = [
    editMode,
    /* NoSideEffects */0,
    undefined
  ];
  var exit = 0;
  if (typeof editMode === "number") {
    if (mouseAction !== 0) {
      return noOp;
    } else {
      return [
              {
                TAG: /* Preview */0,
                _0: {
                  id: targetId,
                  valuesBeforeEdit: targetValues,
                  index: update.index
                }
              },
              {
                TAG: /* ApplyUpdateToValues */0,
                _0: targetId,
                _1: targetValues,
                _2: update
              },
              undefined
            ];
    }
  }
  if (editMode.TAG === /* Preview */0) {
    var preview = editMode._0;
    if (preview.id !== targetId) {
      return noOp;
    }
    switch (mouseAction) {
      case /* MouseMove */1 :
          if (preview.index !== update.index) {
            return [
                    {
                      TAG: /* Preview */0,
                      _0: {
                        id: preview.id,
                        valuesBeforeEdit: preview.valuesBeforeEdit,
                        index: update.index
                      }
                    },
                    {
                      TAG: /* ApplyUpdateToValues */0,
                      _0: preview.id,
                      _1: preview.valuesBeforeEdit,
                      _2: update
                    },
                    undefined
                  ];
          } else {
            return [
                    editMode,
                    {
                      TAG: /* ApplyUpdateToValues */0,
                      _0: preview.id,
                      _1: targetValues,
                      _2: update
                    },
                    undefined
                  ];
          }
      case /* MouseLeave */2 :
          return [
                  /* Inactive */0,
                  {
                    TAG: /* RestoreValues */1,
                    _0: preview.id,
                    _1: preview.valuesBeforeEdit
                  },
                  undefined
                ];
      case /* MouseDown */3 :
          return [
                  {
                    TAG: /* Active */1,
                    _0: {
                      id: preview.id,
                      valuesBeforeEdit: preview.valuesBeforeEdit,
                      mousePosition: /* Inside */0
                    }
                  },
                  /* NoSideEffects */0,
                  undefined
                ];
      case /* MouseEnter */0 :
      case /* MouseUp */4 :
          return noOp;
      
    }
  } else {
    var active = editMode._0;
    if (mouseAction !== 2) {
      if (mouseAction >= 3) {
        exit = 1;
      } else {
        if (active.id !== targetId) {
          return [
                  {
                    TAG: /* Active */1,
                    _0: {
                      id: active.id,
                      valuesBeforeEdit: active.valuesBeforeEdit,
                      mousePosition: /* InsideAnother */{
                        _0: targetId,
                        _1: targetValues,
                        _2: update
                      }
                    }
                  },
                  /* NoSideEffects */0,
                  undefined
                ];
        }
        exit = 1;
      }
    } else {
      if (active.id !== targetId) {
        return [
                {
                  TAG: /* Active */1,
                  _0: {
                    id: active.id,
                    valuesBeforeEdit: active.valuesBeforeEdit,
                    mousePosition: /* Outside */1
                  }
                },
                /* NoSideEffects */0,
                undefined
              ];
      }
      exit = 1;
    }
  }
  if (exit === 1) {
    var active$1 = editMode._0;
    if (active$1.id !== targetId) {
      return noOp;
    }
    var exit$1 = 0;
    switch (mouseAction) {
      case /* MouseEnter */0 :
          return [
                  {
                    TAG: /* Active */1,
                    _0: {
                      id: active$1.id,
                      valuesBeforeEdit: active$1.valuesBeforeEdit,
                      mousePosition: /* Inside */0
                    }
                  },
                  /* NoSideEffects */0,
                  undefined
                ];
      case /* MouseLeave */2 :
          if (active$1.id === targetId) {
            return [
                    {
                      TAG: /* Active */1,
                      _0: {
                        id: targetId,
                        valuesBeforeEdit: active$1.valuesBeforeEdit,
                        mousePosition: /* Outside */1
                      }
                    },
                    /* NoSideEffects */0,
                    undefined
                  ];
          } else {
            return noOp;
          }
      case /* MouseMove */1 :
      case /* MouseDown */3 :
          exit$1 = 2;
          break;
      case /* MouseUp */4 :
          var match = active$1.mousePosition;
          if (typeof match === "number") {
            if (match !== 0) {
              return [
                      /* Inactive */0,
                      /* NoSideEffects */0,
                      [
                        active$1.id,
                        active$1.valuesBeforeEdit
                      ]
                    ];
            } else {
              return [
                      {
                        TAG: /* Preview */0,
                        _0: {
                          id: active$1.id,
                          valuesBeforeEdit: targetValues,
                          index: update.index
                        }
                      },
                      /* NoSideEffects */0,
                      [
                        active$1.id,
                        active$1.valuesBeforeEdit
                      ]
                    ];
            }
          }
          var update$1 = match._2;
          var values = match._1;
          var id = match._0;
          return [
                  {
                    TAG: /* Preview */0,
                    _0: {
                      id: id,
                      valuesBeforeEdit: values,
                      index: update$1.index
                    }
                  },
                  {
                    TAG: /* ApplyUpdateToValues */0,
                    _0: id,
                    _1: values,
                    _2: update$1
                  },
                  [
                    active$1.id,
                    active$1.valuesBeforeEdit
                  ]
                ];
      
    }
    if (exit$1 === 2) {
      return [
              editMode,
              {
                TAG: /* ApplyUpdateToValues */0,
                _0: active$1.id,
                _1: targetValues,
                _2: update
              },
              undefined
            ];
    }
    
  }
  
}

exports.updateEditMode = updateEditMode;
/* No side effect */
