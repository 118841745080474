// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var nextId = {
  contents: 0
};

function create(param) {
  nextId.contents = nextId.contents + 1 | 0;
  return String(nextId.contents);
}

function equals(idOne, idTwo) {
  return idOne === idTwo;
}

function toString(id) {
  return id;
}

exports.create = create;
exports.equals = equals;
exports.toString = toString;
/* No side effect */
