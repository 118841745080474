// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var create_audioContext = (function (_) {
    return new (window.AudioContext || window.webkitAudioContext)();
  });

var audioContext = create_audioContext(undefined);

var resume = (function () {
    audioContext.resume();
  });

var createGlobalFx = (function (_) {
    function createConvolver () {
      const seconds = 2;
      const decay = 2; // to 100?
      const length = Math.floor(seconds * audioContext.sampleRate);
      const impulse = audioContext.createBuffer(2, length, audioContext.sampleRate);
      const impulseL = impulse.getChannelData(0);
      const impulseR = impulse.getChannelData(1);

      /*
      for (let i = 0; i < length; i++) {
        impulseL[i] = impulseR[i] = -1 + (2 * Math.random());
      }
      */

      for (let i = 0; i < length; i++) {
        impulseL[i] = (Math.random() * 2 - 1) * Math.pow(1 - i / length, decay);
        impulseR[i] = (Math.random() * 2 - 1) * Math.pow(1 - i / length, decay);
      }

      const convolver = audioContext.createConvolver();
      convolver.buffer = impulse;

      return convolver;
    }

    const globalFx = {
      masterGain: audioContext.createGain(),
      convolver: createConvolver(),
      convolverGain: audioContext.createGain(),
      warbleOsc: audioContext.createOscillator(),
      warbleGain: audioContext.createGain()
    };

    globalFx.masterGain.connect(audioContext.destination);

    globalFx.convolver.connect(globalFx.convolverGain);
    globalFx.convolverGain.connect(globalFx.masterGain);

    globalFx.convolverGain.gain.value = 0.8;

    globalFx.warbleOsc.frequency.value = 2;
    globalFx.warbleOsc.connect(globalFx.warbleGain);
    globalFx.warbleOsc.start();

    return globalFx;
  });

var setGlobalVolume = (function (volume) {
    globalFx.masterGain.gain.value = Math.pow(volume, 1.6);
  });

var setGlobalWarble = (function (volume) {
    globalFx.warbleGain.gain.value = Math.pow(volume, 1.6);
  });

var globalFx = createGlobalFx(undefined);

var ratios = [
  2,
  3,
  4.16,
  5.43,
  6.79,
  8.21
];

var playHihat = (function (start) {
    const gain = audioContext.createGain();
    const random = Math.random();

    // Bandpass
    const bandpass = audioContext.createBiquadFilter();
    bandpass.type = "bandpass";
    bandpass.frequency.value = 10000 * (1 + random);

    // Highpass
    const highpass = audioContext.createBiquadFilter();
    highpass.type = "highpass";
    highpass.frequency.value = 7000 * (1 + (random * 0.3));

    // Connect the graph
    bandpass.connect(highpass);
    highpass.connect(gain);
    gain.connect(globalFx.masterGain);

    // Create the oscillators
    ratios.forEach(function(ratio) {
      const osc = audioContext.createOscillator();
      osc.type = "square";
      // Frequency is the fundamental * this oscillator's ratio
      osc.frequency.value = fundamental * ratio;
      osc.connect(bandpass);
      osc.start(start);
      osc.stop(start + 0.3);
    });

    // Define the volume envelope
    gain.gain.setValueAtTime(0.00001, start);
    gain.gain.exponentialRampToValueAtTime(1 - (random * 0.15), start + 0.02);
    gain.gain.exponentialRampToValueAtTime(0.3 - (random * 0.15), start + 0.03);
    gain.gain.exponentialRampToValueAtTime(0.00001, start + 0.3);
  });

var playOsc = (function (note, start, time, gain, output) {
    const frequency = 440 * Math.pow(2, note / 12);

    // create nodes
    const osc = audioContext.createOscillator();
    osc.type = 'square';
    osc.frequency.value = frequency;

    const gainNode = audioContext.createGain();
    gainNode.gain.value = gain;

    osc.start(start);
    osc.stop(start + time);

    // routing
    osc.connect(gainNode);
    gainNode.connect(output);

    globalFx.warbleGain.connect(osc.frequency);
  });

var synthFilterRange = 22000.0 - 100.0;

var synthFilterLog = Math.log(22000.0 / 100.0) / Math.log(2.0);

var playSynth = (function (note, gain, pan, filter, start, time) {
    const filterLogScale = synthFilterMin + (synthFilterRange * Math.pow(2, synthFilterLog * (filter - 1)));

    const lowpass = audioContext.createBiquadFilter();
    lowpass.type = 'lowpass';
    lowpass.frequency.value = filterLogScale;

    gain = Math.pow(gain, 1.6);

    const gainNode = audioContext.createGain();
    gainNode.gain.value = gain;

    // createStereoPanner not supported on Safari 12.1.1 as of 13 June 2019.
    let stereoPannerNode;

    if (audioContext.createStereoPanner) {
      stereoPannerNode = audioContext.createStereoPanner();
      stereoPannerNode.pan.value = pan;
    } else {
      stereoPannerNode = audioContext.createPanner();
      stereoPannerNode.setPosition(pan, 0, 1 - Math.abs(pan));
    }

    // schedule
    gainNode.gain.setValueAtTime(gain, start);
    gainNode.gain.setTargetAtTime(0, start, Math.max(0.05, time - 0.05));

    gainNode.connect(lowpass);

    lowpass.connect(stereoPannerNode);

    stereoPannerNode.connect(globalFx.masterGain);
    stereoPannerNode.connect(globalFx.convolver);

    const voiceGain = 1;

    playOsc(note, start, time, voiceGain, gainNode);
  });

function createSchedule(callback) {
  var beatTime = {
    contents: 0
  };
  var timeoutId = {
    contents: undefined
  };
  var bpm = {
    contents: 120
  };
  var onTimeout = function (param) {
    var targetTime = audioContext.currentTime + 0.2;
    var beatLength = 60 / bpm.contents / 4;
    timeoutId.contents = Caml_option.some(setTimeout(onTimeout, 100));
    while(beatTime.contents < targetTime) {
      Curry._1(callback, {
            beatTime: beatTime.contents,
            beatLength: beatLength
          });
      beatTime.contents = beatTime.contents + beatLength;
    };
    
  };
  var start = function (param) {
    var i = timeoutId.contents;
    if (i !== undefined) {
      clearTimeout(Caml_option.valFromOption(i));
    }
    beatTime.contents = audioContext.currentTime;
    return onTimeout(undefined);
  };
  var stop = function (param) {
    var i = timeoutId.contents;
    if (i !== undefined) {
      clearTimeout(Caml_option.valFromOption(i));
      return ;
    }
    
  };
  var setBpm = function (value) {
    bpm.contents = value;
    
  };
  return {
          start: start,
          stop: stop,
          setBpm: setBpm
        };
}

var fundamental = 40;

var synthFilterMin = 100.0;

var synthFilterMax = 22000.0;

exports.create_audioContext = create_audioContext;
exports.audioContext = audioContext;
exports.resume = resume;
exports.createGlobalFx = createGlobalFx;
exports.setGlobalVolume = setGlobalVolume;
exports.setGlobalWarble = setGlobalWarble;
exports.globalFx = globalFx;
exports.fundamental = fundamental;
exports.ratios = ratios;
exports.playHihat = playHihat;
exports.playOsc = playOsc;
exports.synthFilterMin = synthFilterMin;
exports.synthFilterMax = synthFilterMax;
exports.synthFilterRange = synthFilterRange;
exports.synthFilterLog = synthFilterLog;
exports.playSynth = playSynth;
exports.createSchedule = createSchedule;
/* audioContext Not a pure module */
