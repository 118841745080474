// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Utils$ReasonmlSequencer = require("./Utils.bs.js");
var SynthValues$ReasonmlSequencer = require("./SynthValues.bs.js");

function getUpdate(offset, cellSize, values, pageX, pageY) {
  var x = pageX + offset[0] | 0;
  var y = pageY + offset[1] | 0;
  var index = Utils$ReasonmlSequencer.limit(Caml_int32.div(x, cellSize), 0, SynthValues$ReasonmlSequencer.length(values) - 1 | 0);
  var value = 1 - y / cellSize;
  var value$1 = Utils$ReasonmlSequencer.limit(value, 0, 1);
  return {
          index: index,
          value: value$1
        };
}

function Slider(Props) {
  var cellSize = Props.cellSize;
  var viewMode = Props.viewMode;
  var mapValues = Props.mapValues;
  var getValueAt = Props.getValueAt;
  var values = Props.values;
  var disabledIndex = Props.disabledIndex;
  var onAction = Props.onAction;
  var onSetLength = Props.onSetLength;
  var containerRef = React.useRef(null);
  var offsetRef = React.useRef([
        0,
        0
      ]);
  var match;
  if (typeof viewMode === "number") {
    switch (viewMode) {
      case /* Inactive */0 :
          match = [
            "1",
            false
          ];
          break;
      case /* Deactive */1 :
          match = [
            "0.5",
            false
          ];
          break;
      case /* Active */2 :
          match = [
            "1",
            true
          ];
          break;
      
    }
  } else {
    match = [
      "1",
      true
    ];
  }
  var showLabels = match[1];
  var getUpdateFromMouse = function ($$event) {
    return getUpdate(offsetRef.current, cellSize, values, $$event.pageX, $$event.pageY);
  };
  var onMouseEnter = function ($$event) {
    var offsetOption = containerRef.current;
    offsetRef.current = Utils$ReasonmlSequencer.getOffset((offsetOption == null) ? undefined : Caml_option.some(offsetOption));
    return Curry._2(onAction, getUpdateFromMouse($$event), /* MouseEnter */0);
  };
  var onMouseDown = function ($$event) {
    if (typeof viewMode === "number") {
      return ;
    }
    if ($$event.button !== 0) {
      return ;
    }
    $$event.preventDefault();
    var update = getUpdateFromMouse($$event);
    if ($$event.shiftKey) {
      return Curry._1(onSetLength, update.index + 1 | 0);
    } else {
      return Curry._2(onAction, getUpdateFromMouse($$event), /* MouseDown */3);
    }
  };
  var onMouseLeave = function ($$event) {
    return Curry._2(onAction, getUpdateFromMouse($$event), /* MouseLeave */2);
  };
  var onMouseMove = function ($$event) {
    if (viewMode === 1) {
      return Curry._2(onAction, getUpdateFromMouse($$event), /* MouseMove */1);
    }
    
  };
  React.useEffect((function () {
          if (typeof viewMode === "number" && viewMode < 2) {
            return ;
          }
          var onMouseMove = function ($$event) {
            return Curry._2(onAction, getUpdate(offsetRef.current, cellSize, values, $$event.pageX, $$event.pageY), /* MouseMove */1);
          };
          var onMouseUp = function ($$event) {
            return Curry._2(onAction, getUpdate(offsetRef.current, cellSize, values, $$event.pageX, $$event.pageY), /* MouseUp */4);
          };
          Curry._2(document.addEventListener, "mousemove", onMouseMove);
          Curry._2(document.addEventListener, "mouseup", onMouseUp);
          return (function (param) {
                    Curry._2(document.removeEventListener, "mousemove", onMouseMove);
                    Curry._2(document.removeEventListener, "mouseup", onMouseUp);
                    
                  });
        }), [
        values,
        viewMode,
        cellSize,
        onAction
      ]);
  return React.createElement("div", {
              ref: containerRef,
              className: "opacity-transition-1 bg-white relative flex-none no-select",
              style: {
                height: String(cellSize) + "px",
                width: String(Math.imul(cellSize, SynthValues$ReasonmlSequencer.length(values))) + "px",
                opacity: match[0]
              },
              onMouseDown: onMouseDown,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave,
              onMouseMove: onMouseMove
            }, Curry._2(mapValues, (function (valueIndex, value, label) {
                    var match;
                    if (typeof viewMode === "number") {
                      match = [
                        value,
                        0.0
                      ];
                    } else {
                      var index = viewMode._1;
                      match = index === valueIndex ? [
                          Curry._2(getValueAt, index, viewMode._0),
                          value
                        ] : [
                          value,
                          0.0
                        ];
                    }
                    var isDisabled = valueIndex >= disabledIndex;
                    return React.createElement("div", {
                                key: String(valueIndex),
                                className: "absolute " + (
                                  isDisabled ? "bg-near-white" : "bg-light-gray"
                                ),
                                style: {
                                  height: String(cellSize) + "px",
                                  left: String(Math.imul(cellSize, valueIndex)) + "px",
                                  width: String(cellSize) + "px"
                                }
                              }, React.createElement("div", {
                                    className: "absolute absolute--fill " + (
                                      isDisabled ? "bg-moon-gray" : "bg-dark-gray"
                                    ),
                                    style: {
                                      transform: "scale3d(1, " + match[0].toString() + ", 1)",
                                      transformOrigin: "0 100%"
                                    }
                                  }), React.createElement("div", {
                                    className: "absolute absolute--fill bg-hot-pink o-50",
                                    style: {
                                      transform: "scale3d(1, " + match[1].toString() + ", 1)",
                                      transformOrigin: "0 100%"
                                    }
                                  }), showLabels ? React.createElement("div", {
                                      className: "gray absolute absolute--center opacity-transition-1"
                                    }, label) : null);
                  }), values));
}

var make = React.memo(Slider);

exports.getUpdate = getUpdate;
exports.make = make;
/* make Not a pure module */
