// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_int32 = require("rescript/lib/js/caml_int32.js");

function restart(t) {
  return {
          index: 0,
          subIndex: 0,
          subTicks: t.subTicks
        };
}

function advance(loopLength, sync, t) {
  if (loopLength === 0) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "loopLength === 0",
          Error: new Error()
        };
  }
  if (sync) {
    var tick = sync._0;
    var index = Caml_int32.mod_(Caml_int32.div(tick, t.subTicks), loopLength);
    var subIndex = Caml_int32.mod_(tick, t.subTicks);
    return {
            index: index,
            subIndex: subIndex,
            subTicks: t.subTicks
          };
  }
  var nextSubIndex = t.subIndex + 1 | 0;
  if (nextSubIndex < t.subTicks) {
    return {
            index: t.index,
            subIndex: nextSubIndex,
            subTicks: t.subTicks
          };
  }
  var nextIndex = Caml_int32.mod_(t.index + 1 | 0, loopLength);
  return {
          index: nextIndex,
          subIndex: 0,
          subTicks: t.subTicks
        };
}

function merge(incomingLoopLength, incoming, existing) {
  if (existing.index >= incomingLoopLength) {
    return restart(existing);
  } else if (existing.subIndex >= incoming.subTicks) {
    return advance(incomingLoopLength, /* NoSync */0, existing);
  } else {
    return existing;
  }
}

function index(t) {
  return t.index;
}

function subIndex(t) {
  return t.subIndex;
}

function subTicks(t) {
  return t.subTicks;
}

function setSubTicks(subTicks, t) {
  if (subTicks === 0) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "subTicks === 0",
          Error: new Error()
        };
  }
  return {
          index: t.index,
          subIndex: t.subIndex,
          subTicks: subTicks
        };
}

function isFirstTick(t) {
  return t.subIndex === 0;
}

var start = {
  index: 0,
  subIndex: 0,
  subTicks: 1
};

exports.start = start;
exports.restart = restart;
exports.advance = advance;
exports.merge = merge;
exports.index = index;
exports.subIndex = subIndex;
exports.subTicks = subTicks;
exports.setSubTicks = setSubTicks;
exports.isFirstTick = isFirstTick;
/* No side effect */
