// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Timing$ReasonmlSequencer = require("./Timing.bs.js");

var emptyValues = [];

function length(prim) {
  return prim.length;
}

function fromArray(floatConverter, array) {
  return $$Array.map(floatConverter.toFloat, array);
}

function createValueConverter(valueConverterConfig) {
  var floatConverter = valueConverterConfig.floatConverter;
  return {
          defaultValues: (function (length) {
              var array = Curry._1(valueConverterConfig.default, length);
              return $$Array.map(floatConverter.toFloat, array);
            }),
          randomValuesAbsolute: (function (values) {
              var array = Curry._1(valueConverterConfig.randomAbsolute, $$Array.map(floatConverter.fromFloat, values));
              return $$Array.map(floatConverter.toFloat, array);
            }),
          randomValuesRelative: (function (values) {
              var array = Curry._1(valueConverterConfig.randomRelative, $$Array.map(floatConverter.fromFloat, values));
              return $$Array.map(floatConverter.toFloat, array);
            }),
          mapValues: (function (fn, values) {
              return $$Array.mapi((function (index, value) {
                            var realValue = Curry._1(floatConverter.fromFloat, value);
                            var label = Curry._1(valueConverterConfig.toString, realValue);
                            var floatValue = Curry._1(floatConverter.toFloat, realValue);
                            return Curry._3(fn, index, floatValue, label);
                          }), values);
            }),
          getValueAt: (function (index, values) {
              return Curry._1(floatConverter.toFloat, Curry._1(floatConverter.fromFloat, Caml_array.get(values, index)));
            }),
          updateValues: (function (values, update) {
              var existingValue = Curry._1(floatConverter.fromFloat, Caml_array.get(values, update.index));
              var newValue = Curry._1(floatConverter.fromFloat, update.value);
              if (existingValue === newValue) {
                return values;
              } else {
                return $$Array.mapi((function (index, value) {
                              if (update.index === index) {
                                return Curry._1(floatConverter.toFloat, newValue);
                              } else {
                                return value;
                              }
                            }), values);
              }
            }),
          updateSynthParameters: (function (updateGroup, parameters, values) {
              var index = Timing$ReasonmlSequencer.index(updateGroup.timing);
              var value = Curry._1(floatConverter.fromFloat, Caml_array.get(values, index));
              return Curry._3(valueConverterConfig.updateSynthParameters, updateGroup, parameters, value);
            })
        };
}

exports.emptyValues = emptyValues;
exports.length = length;
exports.fromArray = fromArray;
exports.createValueConverter = createValueConverter;
/* No side effect */
