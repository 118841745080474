// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Random = require("rescript/lib/js/random.js");
var ReactDom = require("react-dom");
var App$ReasonmlSequencer = require("./App.bs.js");

Random.self_init(undefined);

var root = document.querySelector("#app");

if (!(root == null)) {
  ReactDom.render(React.createElement(App$ReasonmlSequencer.make, {}), root);
}

/*  Not a pure module */
