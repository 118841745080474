// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");

function $$Range(Props) {
  var value = Props.value;
  var min = Props.min;
  var max = Props.max;
  var step = Props.step;
  var onChange = Props.onChange;
  var children = Props.children;
  var scale = 1.0 - (value - min) / (max - min);
  var scaleString = String(scale * -100.0);
  var style = {
    transform: "translate3d(" + scaleString + "%, 0, 0)",
    transformOrigin: "0 100%"
  };
  return React.createElement("div", {
              className: "relative w4 h2 flex-none"
            }, React.createElement("div", {
                  className: "relative bg-light-gray dark-gray overflow-hidden h-100"
                }, React.createElement("div", {
                      className: "absolute absolute--fill bg-gray",
                      style: style
                    })), React.createElement("div", {
                  className: "absolute absolute--fill flex items-center justify-center"
                }, children), React.createElement("input", {
                  className: "input-range-reset pointer absolute absolute--fill w-100",
                  max: max.toString(),
                  min: min.toString(),
                  step: step,
                  type: "range",
                  value: value.toString(),
                  onChange: (function ($$event) {
                      return Curry._1(onChange, $$event.target.value);
                    })
                }));
}

var make = $$Range;

exports.make = make;
/* react Not a pure module */
