// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Random = require("rescript/lib/js/random.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Id$ReasonmlSequencer = require("./Id.bs.js");
var Range$ReasonmlSequencer = require("./Range.bs.js");
var Track$ReasonmlSequencer = require("./Track.bs.js");
var Utils$ReasonmlSequencer = require("./Utils.bs.js");
var Scales$ReasonmlSequencer = require("./Scales.bs.js");
var WebAudio$ReasonmlSequencer = require("./WebAudio.bs.js");
var UndoBuffer$ReasonmlSequencer = require("./UndoBuffer.bs.js");
var SynthValues$ReasonmlSequencer = require("./SynthValues.bs.js");
var SynthInstance$ReasonmlSequencer = require("./SynthInstance.bs.js");
var TrackEditMode$ReasonmlSequencer = require("./TrackEditMode.bs.js");
var SynthValuesHelpers$ReasonmlSequencer = require("./SynthValuesHelpers.bs.js");

function getValueConverter(globalParameters, parameter) {
  switch (parameter) {
    case /* Octave */0 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.octave);
    case /* PitchWithNotes */1 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.pitchWithFirstNote(globalParameters.scale));
    case /* PitchWithoutNotes */2 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.pitch(globalParameters.scale));
    case /* Gain */3 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.gain);
    case /* Pan */4 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.pan);
    case /* Chance */5 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.chance);
    case /* Length */6 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.length);
    case /* Filter */7 :
        return SynthValues$ReasonmlSequencer.createValueConverter(SynthValuesHelpers$ReasonmlSequencer.filter);
    
  }
}

function defaultSynthTracks(globalParameters) {
  return List.map((function (param) {
                var parameter = param[1];
                var valueConverter = getValueConverter(globalParameters, parameter);
                var values = Curry._1(valueConverter.defaultValues, 16);
                return {
                        id: Id$ReasonmlSequencer.create(undefined),
                        label: param[0],
                        parameter: parameter,
                        synthInstance: SynthInstance$ReasonmlSequencer.create(values)
                      };
              }), {
              hd: [
                "Octave",
                /* Octave */0
              ],
              tl: {
                hd: [
                  "Pitch 1",
                  /* PitchWithNotes */1
                ],
                tl: {
                  hd: [
                    "Pitch 2",
                    /* PitchWithoutNotes */2
                  ],
                  tl: {
                    hd: [
                      "Pitch 3",
                      /* PitchWithoutNotes */2
                    ],
                    tl: {
                      hd: [
                        "Gain",
                        /* Gain */3
                      ],
                      tl: {
                        hd: [
                          "Pan",
                          /* Pan */4
                        ],
                        tl: {
                          hd: [
                            "Chance",
                            /* Chance */5
                          ],
                          tl: {
                            hd: [
                              "Length",
                              /* Length */6
                            ],
                            tl: {
                              hd: [
                                "Filter",
                                /* Filter */7
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function mergeSynthTracks(incomingSynthTracks, existingSynthTracks) {
  var existingSynthTracksCurrent = {
    contents: existingSynthTracks
  };
  return List.map((function (incomingSynthTrack) {
                var match = existingSynthTracksCurrent.contents;
                if (match) {
                  existingSynthTracksCurrent.contents = match.tl;
                  return {
                          id: incomingSynthTrack.id,
                          label: incomingSynthTrack.label,
                          parameter: incomingSynthTrack.parameter,
                          synthInstance: SynthInstance$ReasonmlSequencer.merge(incomingSynthTrack.synthInstance, match.hd.synthInstance)
                        };
                } else {
                  return incomingSynthTrack;
                }
              }), incomingSynthTracks);
}

function updateSynthTrackById(id, fn, synthTracks) {
  return List.map((function (synthTrack) {
                if (Id$ReasonmlSequencer.equals(synthTrack.id, id)) {
                  return Curry._1(fn, synthTrack);
                } else {
                  return synthTrack;
                }
              }), synthTracks);
}

function updateSynthInstance(fn, synthTrack) {
  return {
          id: synthTrack.id,
          label: synthTrack.label,
          parameter: synthTrack.parameter,
          synthInstance: Curry._1(fn, synthTrack.synthInstance)
        };
}

function randomTranspose(param) {
  return Utils$ReasonmlSequencer.randomInt(-5, 6);
}

function initialState(param) {
  var match = Utils$ReasonmlSequencer.randomArrayValue(Scales$ReasonmlSequencer.scales);
  var initialGlobalParameters_scale = match[1];
  var initialGlobalParameters = {
    repeatNotesEverySubTick: false,
    scale: initialGlobalParameters_scale
  };
  return {
          synthTracks: defaultSynthTracks(initialGlobalParameters),
          synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.create(12, /* [] */0),
          synthTracksRedoBuffer: UndoBuffer$ReasonmlSequencer.create(12, /* [] */0),
          isPlaying: false,
          volume: 1.0,
          warble: 0.0,
          bpm: 120.0,
          tick: 0,
          sync: false,
          globalParameters: initialGlobalParameters,
          editMode: /* Inactive */0,
          globalTranspose: Utils$ReasonmlSequencer.randomInt(-5, 6)
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* AdvancePlayback */0 :
          var nextTick = state.tick + 1 | 0;
          var sync = state.sync ? /* Sync */({
                _0: nextTick
              }) : /* NoSync */0;
          return {
                  synthTracks: List.map((function (param) {
                          return updateSynthInstance((function (param) {
                                        return SynthInstance$ReasonmlSequencer.advance(sync, param);
                                      }), param);
                        }), state.synthTracks),
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: nextTick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* RandomiseAll */1 :
          return {
                  synthTracks: List.map((function (synthTrack) {
                          var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                          return {
                                  id: synthTrack.id,
                                  label: synthTrack.label,
                                  parameter: synthTrack.parameter,
                                  synthInstance: SynthInstance$ReasonmlSequencer.randomAbsolute(valueConverter, synthTrack.synthInstance)
                                };
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: Utils$ReasonmlSequencer.randomInt(-5, 6)
                };
      case /* Redo */2 :
          var synthTracks = UndoBuffer$ReasonmlSequencer.read(state.synthTracksRedoBuffer);
          if (synthTracks !== undefined) {
            return {
                    synthTracks: mergeSynthTracks(synthTracks, state.synthTracks),
                    synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                    synthTracksRedoBuffer: UndoBuffer$ReasonmlSequencer.pop(state.synthTracksRedoBuffer),
                    isPlaying: state.isPlaying,
                    volume: state.volume,
                    warble: state.warble,
                    bpm: state.bpm,
                    tick: state.tick,
                    sync: state.sync,
                    globalParameters: state.globalParameters,
                    editMode: state.editMode,
                    globalTranspose: state.globalTranspose
                  };
          } else {
            return state;
          }
      case /* ResetAll */3 :
          return {
                  synthTracks: defaultSynthTracks(state.globalParameters),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* Restart */4 :
          return {
                  synthTracks: List.map((function (param) {
                          return updateSynthInstance(SynthInstance$ReasonmlSequencer.restart, param);
                        }), state.synthTracks),
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: 0,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* Undo */5 :
          var synthTracks$1 = UndoBuffer$ReasonmlSequencer.read(state.synthTracksUndoBuffer);
          if (synthTracks$1 !== undefined) {
            return {
                    synthTracks: mergeSynthTracks(synthTracks$1, state.synthTracks),
                    synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.pop(state.synthTracksUndoBuffer),
                    synthTracksRedoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksRedoBuffer),
                    isPlaying: state.isPlaying,
                    volume: state.volume,
                    warble: state.warble,
                    bpm: state.bpm,
                    tick: state.tick,
                    sync: state.sync,
                    globalParameters: state.globalParameters,
                    editMode: state.editMode,
                    globalTranspose: state.globalTranspose
                  };
          } else {
            return state;
          }
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* RandomiseAbsolute */0 :
          return {
                  synthTracks: updateSynthTrackById(action._0, (function (synthTrack) {
                          var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                          return {
                                  id: synthTrack.id,
                                  label: synthTrack.label,
                                  parameter: synthTrack.parameter,
                                  synthInstance: SynthInstance$ReasonmlSequencer.randomAbsolute(valueConverter, synthTrack.synthInstance)
                                };
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* RandomiseRelative */1 :
          return {
                  synthTracks: updateSynthTrackById(action._0, (function (synthTrack) {
                          var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                          return {
                                  id: synthTrack.id,
                                  label: synthTrack.label,
                                  parameter: synthTrack.parameter,
                                  synthInstance: SynthInstance$ReasonmlSequencer.randomRelative(valueConverter, synthTrack.synthInstance)
                                };
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* Reset */2 :
          return {
                  synthTracks: updateSynthTrackById(action._0, (function (synthTrack) {
                          var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                          return {
                                  id: synthTrack.id,
                                  label: synthTrack.label,
                                  parameter: synthTrack.parameter,
                                  synthInstance: SynthInstance$ReasonmlSequencer.reset(valueConverter, synthTrack.synthInstance)
                                };
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetBpm */3 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: action._0,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetLoopLength */4 :
          var index = action._1;
          return {
                  synthTracks: updateSynthTrackById(action._0, (function (param) {
                          return updateSynthInstance((function (param) {
                                        return SynthInstance$ReasonmlSequencer.setLoopLength(index, param);
                                      }), param);
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetPlayback */5 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: action._0,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetSubTicks */6 :
          var subTicks = action._1;
          return {
                  synthTracks: updateSynthTrackById(action._0, (function (param) {
                          return updateSynthInstance((function (param) {
                                        return SynthInstance$ReasonmlSequencer.setSubTicks(subTicks, param);
                                      }), param);
                        }), state.synthTracks),
                  synthTracksUndoBuffer: UndoBuffer$ReasonmlSequencer.write(state.synthTracks, state.synthTracksUndoBuffer),
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetSync */7 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: action._0,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetVolume */8 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: action._0,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* SetWarble */9 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: action._0,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      case /* TrackEditMode */10 :
          var id = action._0;
          var selectedSynthTrack = List.find((function (synthTrack) {
                  return synthTrack.id === id;
                }), state.synthTracks);
          var selectedValues = SynthInstance$ReasonmlSequencer.values(selectedSynthTrack.synthInstance);
          var match = TrackEditMode$ReasonmlSequencer.updateEditMode(id, selectedValues, action._1, action._2, state.editMode);
          var valuesToUndo = match[2];
          var sideEffects = match[1];
          var synthTracks$2;
          if (typeof sideEffects === "number") {
            synthTracks$2 = state.synthTracks;
          } else if (sideEffects.TAG === /* ApplyUpdateToValues */0) {
            var update = sideEffects._2;
            var values = sideEffects._1;
            synthTracks$2 = updateSynthTrackById(sideEffects._0, (function (synthTrack) {
                    var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                    return {
                            id: synthTrack.id,
                            label: synthTrack.label,
                            parameter: synthTrack.parameter,
                            synthInstance: SynthInstance$ReasonmlSequencer.applyUpdate(valueConverter, values, update, synthTrack.synthInstance)
                          };
                  }), state.synthTracks);
          } else {
            var values$1 = sideEffects._1;
            synthTracks$2 = updateSynthTrackById(sideEffects._0, (function (param) {
                    return updateSynthInstance((function (param) {
                                  return SynthInstance$ReasonmlSequencer.setValues(values$1, param);
                                }), param);
                  }), state.synthTracks);
          }
          var synthTracksUndoBuffer;
          if (valuesToUndo !== undefined) {
            var values$2 = valuesToUndo[1];
            var synthTracks$3 = updateSynthTrackById(valuesToUndo[0], (function (param) {
                    return updateSynthInstance((function (param) {
                                  return SynthInstance$ReasonmlSequencer.setValues(values$2, param);
                                }), param);
                  }), state.synthTracks);
            synthTracksUndoBuffer = UndoBuffer$ReasonmlSequencer.write(synthTracks$3, state.synthTracksUndoBuffer);
          } else {
            synthTracksUndoBuffer = state.synthTracksUndoBuffer;
          }
          return {
                  synthTracks: synthTracks$2,
                  synthTracksUndoBuffer: synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: state.globalParameters,
                  editMode: match[0],
                  globalTranspose: state.globalTranspose
                };
      case /* UpdateGlobalParameters */11 :
          return {
                  synthTracks: state.synthTracks,
                  synthTracksUndoBuffer: state.synthTracksUndoBuffer,
                  synthTracksRedoBuffer: state.synthTracksRedoBuffer,
                  isPlaying: state.isPlaying,
                  volume: state.volume,
                  warble: state.warble,
                  bpm: state.bpm,
                  tick: state.tick,
                  sync: state.sync,
                  globalParameters: action._0,
                  editMode: state.editMode,
                  globalTranspose: state.globalTranspose
                };
      
    }
  }
}

function scheduleCallback(getState, beatTime, beatLength) {
  var initialParameters_notes = [];
  var initialParameters = {
    chance: 1.0,
    filter: 1.0,
    gain: 1.0,
    length: 1.0,
    notes: initialParameters_notes,
    pan: 0.0,
    transpose: 0
  };
  var state = Curry._1(getState, undefined);
  var parameters = List.fold_left((function (parameters, synthTrack) {
          var synthInstance = synthTrack.synthInstance;
          var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
          var group_globalParameters = state.globalParameters;
          var group_timing = SynthInstance$ReasonmlSequencer.timing(synthInstance);
          var group = {
            globalParameters: group_globalParameters,
            timing: group_timing
          };
          return Curry._3(valueConverter.updateSynthParameters, group, parameters, SynthInstance$ReasonmlSequencer.values(synthInstance));
        }), initialParameters, state.synthTracks);
  if (parameters.chance > 0.0) {
    var offset = state.tick % 2 === 1 ? Math.pow(state.warble, 2.3) * 0.3 : 0.0;
    $$Array.sort((function (a, b) {
            return a - b | 0;
          }), parameters.notes);
    var lastNotePlayed = {
      contents: -1
    };
    $$Array.iter((function (incomingNote) {
            if (incomingNote > lastNotePlayed.contents && Random.$$float(1) <= parameters.chance) {
              lastNotePlayed.contents = incomingNote;
              return WebAudio$ReasonmlSequencer.playSynth((incomingNote + parameters.transpose | 0) + state.globalTranspose | 0, parameters.gain, parameters.pan, parameters.filter, beatTime + beatLength * offset, beatLength * parameters.length);
            }
            
          }), parameters.notes);
  }
  return WebAudio$ReasonmlSequencer.playHihat(beatTime);
}

function useReducerRealTime(reducer, initialState) {
  var match = React.useState(function () {
        return Curry._1(initialState, undefined);
      });
  var setState = match[1];
  var state = match[0];
  var stateRef = React.useRef(state);
  var getState = React.useCallback((function (param) {
          return stateRef.current;
        }), []);
  var dispatch = React.useCallback((function (action) {
          var previousState = Curry._1(getState, undefined);
          var nextState = Curry._2(reducer, previousState, action);
          stateRef.current = nextState;
          return Curry._1(setState, (function (param) {
                        return nextState;
                      }));
        }), [reducer]);
  return [
          state,
          dispatch,
          getState
        ];
}

function useScheduler(param) {
  var getState = param[2];
  var dispatch = param[1];
  var schedulerRef = React.useRef(undefined);
  var scheduler = schedulerRef.current;
  var scheduler$1;
  if (scheduler !== undefined) {
    scheduler$1 = scheduler;
  } else {
    var scheduler$2 = WebAudio$ReasonmlSequencer.createSchedule(function (scheduleTime) {
          scheduleCallback(getState, scheduleTime.beatTime, scheduleTime.beatLength);
          return Curry._1(dispatch, /* AdvancePlayback */0);
        });
    schedulerRef.current = scheduler$2;
    scheduler$1 = scheduler$2;
  }
  React.useEffect((function () {
          return (function (param) {
                    return Curry._1(scheduler$1.stop, undefined);
                  });
        }), []);
  var dispatchWrapper = React.useCallback((function (action) {
          var previousState = Curry._1(getState, undefined);
          Curry._1(dispatch, action);
          var nextState = Curry._1(getState, undefined);
          Curry._1(scheduler$1.setBpm, nextState.bpm);
          var match = previousState.isPlaying;
          var match$1 = nextState.isPlaying;
          if (match) {
            if (match$1) {
              return ;
            } else {
              return Curry._1(scheduler$1.stop, undefined);
            }
          } else if (match$1) {
            return Curry._1(scheduler$1.start, undefined);
          } else {
            return ;
          }
        }), []);
  return [
          param[0],
          dispatchWrapper
        ];
}

function App(Props) {
  var match = useScheduler(useReducerRealTime(reducer, initialState));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          WebAudio$ReasonmlSequencer.setGlobalVolume(state.volume);
          
        }), [state.volume]);
  React.useEffect((function () {
          WebAudio$ReasonmlSequencer.setGlobalWarble(state.warble * 2.9);
          
        }), [state.warble]);
  return React.createElement("div", {
              className: "ma4"
            }, React.createElement("div", {
                  className: "flex items-center f6"
                }, React.createElement("button", {
                      className: "w3 h2 flex-none",
                      onClick: (function (_event) {
                          WebAudio$ReasonmlSequencer.resume(undefined);
                          return Curry._1(dispatch, {
                                      TAG: /* SetPlayback */5,
                                      _0: !state.isPlaying
                                    });
                        })
                    }, state.isPlaying ? "Stop" : "Play"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement(Range$ReasonmlSequencer.make, {
                      value: state.bpm,
                      min: 40.0,
                      max: 200.0,
                      step: 1.0,
                      onChange: (function (value) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetBpm */3,
                                      _0: value
                                    });
                        }),
                      children: React.createElement("span", undefined, "BPM: " + state.bpm.toString())
                    }), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement(Range$ReasonmlSequencer.make, {
                      value: state.volume,
                      min: 0.0,
                      max: 1.0,
                      step: 0.01,
                      onChange: (function (value) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetVolume */8,
                                      _0: value
                                    });
                        }),
                      children: "Volume: " + String(Js_math.floor(state.volume * 100.0)) + "%"
                    }), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement(Range$ReasonmlSequencer.make, {
                      value: state.warble,
                      min: 0.0,
                      max: 1.0,
                      step: 0.01,
                      onChange: (function (value) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetWarble */9,
                                      _0: value
                                    });
                        }),
                      children: "Warble: " + String(Js_math.floor(state.warble * 100.0)) + "%"
                    }), React.createElement("span", {
                      className: "db w2 flex-none"
                    }), React.createElement("button", {
                      className: "w4 h2 flex-none",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, /* Restart */4);
                        })
                    }, "Restart All"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("button", {
                      className: "w4 h2 flex-none",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, /* RandomiseAll */1);
                        })
                    }, "Randomise All"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("button", {
                      className: "w4 h2 flex-none",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, /* ResetAll */3);
                        })
                    }, "Reset All"), React.createElement("span", {
                      className: "db w2 flex-none"
                    }), React.createElement("button", {
                      className: "w3 h2 flex-none",
                      disabled: UndoBuffer$ReasonmlSequencer.isEmpty(state.synthTracksUndoBuffer),
                      onClick: (function (_event) {
                          return Curry._1(dispatch, /* Undo */5);
                        })
                    }, "Undo"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("button", {
                      className: "w3 h2 flex-none",
                      disabled: UndoBuffer$ReasonmlSequencer.isEmpty(state.synthTracksRedoBuffer),
                      onClick: (function (_event) {
                          return Curry._1(dispatch, /* Redo */2);
                        })
                    }, "Redo"), React.createElement("span", {
                      className: "db w2 flex-none"
                    }), React.createElement("label", {
                      className: "flex-none"
                    }, React.createElement("input", {
                          checked: state.sync,
                          type: "checkbox",
                          onChange: (function ($$event) {
                              return Curry._1(dispatch, {
                                          TAG: /* SetSync */7,
                                          _0: $$event.target.checked
                                        });
                            })
                        }), React.createElement("span", {
                          className: "ml2"
                        }, "Sync")), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("label", {
                      className: "flex-none"
                    }, React.createElement("input", {
                          checked: state.globalParameters.repeatNotesEverySubTick,
                          type: "checkbox",
                          onChange: (function ($$event) {
                              var init = state.globalParameters;
                              return Curry._1(dispatch, {
                                          TAG: /* UpdateGlobalParameters */11,
                                          _0: {
                                            repeatNotesEverySubTick: $$event.target.checked,
                                            scale: init.scale
                                          }
                                        });
                            })
                        }), React.createElement("span", {
                          className: "ml2"
                        }, "Repeat notes"))), React.createElement("span", {
                  className: "dib h2"
                }), React.createElement("div", {
                  className: "flex"
                }, React.createElement("p", {
                      className: "ma0"
                    }, "Scale:"), $$Array.map((function (param) {
                        var scale = param[1];
                        var label = param[0];
                        return React.createElement("label", {
                                    key: label,
                                    className: "ml4"
                                  }, React.createElement("input", {
                                        checked: scale === state.globalParameters.scale,
                                        name: "scale",
                                        type: "radio",
                                        value: label,
                                        onChange: (function (_event) {
                                            var init = state.globalParameters;
                                            return Curry._1(dispatch, {
                                                        TAG: /* UpdateGlobalParameters */11,
                                                        _0: {
                                                          repeatNotesEverySubTick: init.repeatNotesEverySubTick,
                                                          scale: scale
                                                        }
                                                      });
                                          })
                                      }), React.createElement("span", {
                                        className: "ml2"
                                      }, label));
                      }), Scales$ReasonmlSequencer.scales)), React.createElement("span", {
                  className: "dib h2"
                }), $$Array.of_list(List.mapi((function (index, synthTrack) {
                        var valueConverter = getValueConverter(state.globalParameters, synthTrack.parameter);
                        return React.createElement(React.Fragment, {
                                    children: null,
                                    key: Id$ReasonmlSequencer.toString(synthTrack.id)
                                  }, index > 0 ? React.createElement("span", {
                                          className: "dib h1 flex-none"
                                        }) : null, React.createElement(Track$ReasonmlSequencer.make, {
                                        id: synthTrack.id,
                                        label: synthTrack.label,
                                        valueConverter: valueConverter,
                                        synthInstance: synthTrack.synthInstance,
                                        editMode: state.editMode,
                                        dispatch: dispatch
                                      }));
                      }), state.synthTracks)));
}

var make = App;

exports.getValueConverter = getValueConverter;
exports.defaultSynthTracks = defaultSynthTracks;
exports.mergeSynthTracks = mergeSynthTracks;
exports.updateSynthTrackById = updateSynthTrackById;
exports.updateSynthInstance = updateSynthInstance;
exports.randomTranspose = randomTranspose;
exports.initialState = initialState;
exports.reducer = reducer;
exports.scheduleCallback = scheduleCallback;
exports.useReducerRealTime = useReducerRealTime;
exports.useScheduler = useScheduler;
exports.make = make;
/* react Not a pure module */
