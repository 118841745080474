// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_array = require("rescript/lib/js/caml_array.js");

function LoopProgress(Props) {
  var cellSize = Props.cellSize;
  var highlightedIndex = Props.highlightedIndex;
  var disabledIndex = Props.disabledIndex;
  var length = Props.length;
  var onSetLength = Props.onSetLength;
  var height = "16px";
  return React.createElement("div", {
              className: "relative flex-none",
              style: {
                height: height,
                width: String(Math.imul(cellSize, length)) + "px"
              }
            }, $$Array.mapi((function (index, param) {
                    var isDisabled = index >= disabledIndex;
                    var backgroundClassName = isDisabled ? "bg-light-gray" : (
                        index === highlightedIndex ? "bg-gold" : "bg-white"
                      );
                    var onClick = function (_event) {
                      return Curry._1(onSetLength, index + 1 | 0);
                    };
                    return React.createElement("button", {
                                key: String(index),
                                className: "input-reset absolute ba " + backgroundClassName,
                                style: {
                                  height: height,
                                  left: String(Math.imul(cellSize, index)) + "px",
                                  width: String(cellSize) + "px"
                                },
                                onClick: onClick
                              });
                  }), Caml_array.make(length, undefined)));
}

var make = React.memo(LoopProgress);

exports.make = make;
/* make Not a pure module */
