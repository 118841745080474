// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Random = require("rescript/lib/js/random.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Utils$ReasonmlSequencer = require("./Utils.bs.js");
var Timing$ReasonmlSequencer = require("./Timing.bs.js");

function intRangeConverters(min, max) {
  return {
          fromFloat: (function (value) {
              var range = -1 + Js_math.ceil(value * ((max - min | 0) + 1 | 0)) | 0;
              return min + (
                      0 > range ? 0 : range
                    ) | 0;
            }),
          toFloat: (function (value) {
              return ((value - min | 0) + 1 | 0) / ((max - min | 0) + 1 | 0);
            })
        };
}

function randomIntAbsolute(min, max, values) {
  return $$Array.map((function (param) {
                return Utils$ReasonmlSequencer.randomInt(min, max);
              }), values);
}

function randomIntRelative(min, max, randomRelativeRange, values) {
  return $$Array.map((function (value) {
                var deltaMin = Caml.caml_int_max(min, value - randomRelativeRange | 0);
                var deltaMax = Caml.caml_int_min(max, value + randomRelativeRange | 0);
                return Utils$ReasonmlSequencer.randomInt(deltaMin, deltaMax);
              }), values);
}

function floatRangeConverters(min, max) {
  return {
          fromFloat: (function (value) {
              return min + (max - min) * value;
            }),
          toFloat: (function (value) {
              return (value - min) / (max - min);
            })
        };
}

function randomFloatAbsolute(min, max, values) {
  return $$Array.map((function (param) {
                return Utils$ReasonmlSequencer.randomFloat(min, max);
              }), values);
}

function randomFloatRelative(min, max, randomRelativeRange, values) {
  return $$Array.map((function (value) {
                var deltaMin = Caml.caml_float_max(min, value - randomRelativeRange);
                var deltaMax = Caml.caml_float_min(max, value + randomRelativeRange);
                return Utils$ReasonmlSequencer.randomFloat(deltaMin, deltaMax);
              }), values);
}

function optionalArrayConverters(array) {
  return {
          fromFloat: (function (value) {
              var index = Caml.caml_int_max(1, Js_math.ceil(value * (array.length + 1 | 0)));
              if (index === 1) {
                return ;
              } else {
                return Caml_option.some(Caml_array.get(array, index - 2 | 0));
              }
            }),
          toFloat: (function (value) {
              var index = value !== undefined ? 2 + Utils$ReasonmlSequencer.getArrayIndex(array, Caml_option.valFromOption(value), 0) | 0 : 1;
              return index / (array.length + 1 | 0);
            })
        };
}

function intToPlusMinus(value) {
  return (
          value >= 0 ? "+" : ""
        ) + String(value);
}

function floatToPercentageString(value) {
  return Math.round(value * 100.0).toString() + "%";
}

function createPitch($$default, scale) {
  return {
          floatConverter: optionalArrayConverters(scale),
          default: (function (length) {
              return Curry._2($$default, length, scale);
            }),
          randomAbsolute: (function (values) {
              var chance = 0.65 + Random.$$float(0.2);
              return $$Array.map((function (param) {
                            if (Random.$$float(1.0) <= chance) {
                              return Utils$ReasonmlSequencer.randomArrayValue(scale);
                            }
                            
                          }), values);
            }),
          randomRelative: (function (values) {
              return $$Array.map((function (value) {
                            var index = value !== undefined ? Utils$ReasonmlSequencer.getArrayIndex(scale, value, 0) + 1 | 0 : 0;
                            var deltaMin = Caml.caml_int_max(0, index - 2 | 0);
                            var deltaMax = Caml.caml_int_min(scale.length, index + 2 | 0);
                            var randomIndex = Utils$ReasonmlSequencer.randomInt(deltaMin, deltaMax);
                            if (randomIndex === 0) {
                              return ;
                            } else {
                              return Caml_array.get(scale, randomIndex - 1 | 0);
                            }
                          }), values);
            }),
          updateSynthParameters: (function (param, parameters, value) {
              return {
                      chance: parameters.chance,
                      filter: parameters.filter,
                      gain: parameters.gain,
                      length: parameters.length,
                      notes: (param.globalParameters.repeatNotesEverySubTick || Timing$ReasonmlSequencer.isFirstTick(param.timing)) && value !== undefined ? $$Array.append(parameters.notes, [value]) : parameters.notes,
                      pan: parameters.pan,
                      transpose: parameters.transpose
                    };
            }),
          toString: (function (value) {
              if (value !== undefined) {
                return String(value + 1 | 0);
              } else {
                return "-";
              }
            })
        };
}

var octave_floatConverter = intRangeConverters(-3, 2);

function octave_default(length) {
  return Caml_array.make(length, 0);
}

function octave_randomAbsolute(param) {
  return randomIntAbsolute(-3, 2, param);
}

function octave_randomRelative(param) {
  return randomIntRelative(-3, 2, 1, param);
}

function octave_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance,
          filter: parameters.filter,
          gain: parameters.gain,
          length: parameters.length,
          notes: parameters.notes,
          pan: parameters.pan,
          transpose: parameters.transpose + Math.imul(value, 12) | 0
        };
}

var octave = {
  floatConverter: octave_floatConverter,
  default: octave_default,
  randomAbsolute: octave_randomAbsolute,
  randomRelative: octave_randomRelative,
  updateSynthParameters: octave_updateSynthParameters,
  toString: intToPlusMinus
};

function pitchWithFirstNote(param) {
  return createPitch((function (length, scale) {
                var array = Caml_array.make(length, undefined);
                Caml_array.set(array, 0, Utils$ReasonmlSequencer.randomArrayValue(scale));
                return array;
              }), param);
}

function pitch(param) {
  return createPitch((function (length, _array) {
                return Caml_array.make(length, undefined);
              }), param);
}

var gain_floatConverter = floatRangeConverters(0.0, 1.0);

function gain_default(length) {
  return Caml_array.make(length, 1.0);
}

function gain_randomAbsolute(param) {
  return randomFloatAbsolute(0.2, 1.0, param);
}

function gain_randomRelative(param) {
  return randomFloatRelative(0.0, 1.0, 0.2, param);
}

function gain_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance,
          filter: parameters.filter,
          gain: parameters.gain * value,
          length: parameters.length,
          notes: parameters.notes,
          pan: parameters.pan,
          transpose: parameters.transpose
        };
}

var gain = {
  floatConverter: gain_floatConverter,
  default: gain_default,
  randomAbsolute: gain_randomAbsolute,
  randomRelative: gain_randomRelative,
  updateSynthParameters: gain_updateSynthParameters,
  toString: floatToPercentageString
};

var pan_floatConverter = floatRangeConverters(-1.0, 1.0);

function pan_default(length) {
  return Caml_array.make(length, 0.0);
}

function pan_randomAbsolute(param) {
  return randomFloatAbsolute(-1.0, 1.0, param);
}

function pan_randomRelative(param) {
  return randomFloatRelative(-1.0, 1.0, 0.2, param);
}

function pan_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance,
          filter: parameters.filter,
          gain: parameters.gain,
          length: parameters.length,
          notes: parameters.notes,
          pan: parameters.pan + value,
          transpose: parameters.transpose
        };
}

var pan = {
  floatConverter: pan_floatConverter,
  default: pan_default,
  randomAbsolute: pan_randomAbsolute,
  randomRelative: pan_randomRelative,
  updateSynthParameters: pan_updateSynthParameters,
  toString: floatToPercentageString
};

var chance_floatConverter = floatRangeConverters(0.0, 1.0);

function chance_default(length) {
  return Caml_array.make(length, 1.0);
}

function chance_randomAbsolute(param) {
  return randomFloatAbsolute(0.0, 1.0, param);
}

function chance_randomRelative(param) {
  return randomFloatRelative(0.0, 1.0, 0.2, param);
}

function chance_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance * value,
          filter: parameters.filter,
          gain: parameters.gain,
          length: parameters.length,
          notes: parameters.notes,
          pan: parameters.pan,
          transpose: parameters.transpose
        };
}

var chance = {
  floatConverter: chance_floatConverter,
  default: chance_default,
  randomAbsolute: chance_randomAbsolute,
  randomRelative: chance_randomRelative,
  updateSynthParameters: chance_updateSynthParameters,
  toString: floatToPercentageString
};

var length_floatConverter = floatRangeConverters(0.0, 2.0);

function length_default(length) {
  return Caml_array.make(length, 1.0);
}

function length_randomAbsolute(param) {
  return randomFloatAbsolute(0.0, 2.0, param);
}

function length_randomRelative(param) {
  return randomFloatRelative(0.0, 2.0, 0.2, param);
}

function length_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance,
          filter: parameters.filter,
          gain: parameters.gain,
          length: parameters.length * value,
          notes: parameters.notes,
          pan: parameters.pan,
          transpose: parameters.transpose
        };
}

var length = {
  floatConverter: length_floatConverter,
  default: length_default,
  randomAbsolute: length_randomAbsolute,
  randomRelative: length_randomRelative,
  updateSynthParameters: length_updateSynthParameters,
  toString: floatToPercentageString
};

var filter_floatConverter = floatRangeConverters(0.0, 1.0);

function filter_default(length) {
  return Caml_array.make(length, 1.0);
}

function filter_randomAbsolute(param) {
  return randomFloatAbsolute(0.0, 1.0, param);
}

function filter_randomRelative(param) {
  return randomFloatRelative(0.0, 1.0, 0.2, param);
}

function filter_updateSynthParameters(_group, parameters, value) {
  return {
          chance: parameters.chance,
          filter: parameters.filter * value,
          gain: parameters.gain,
          length: parameters.length,
          notes: parameters.notes,
          pan: parameters.pan,
          transpose: parameters.transpose
        };
}

var filter = {
  floatConverter: filter_floatConverter,
  default: filter_default,
  randomAbsolute: filter_randomAbsolute,
  randomRelative: filter_randomRelative,
  updateSynthParameters: filter_updateSynthParameters,
  toString: floatToPercentageString
};

exports.intRangeConverters = intRangeConverters;
exports.randomIntAbsolute = randomIntAbsolute;
exports.randomIntRelative = randomIntRelative;
exports.floatRangeConverters = floatRangeConverters;
exports.randomFloatAbsolute = randomFloatAbsolute;
exports.randomFloatRelative = randomFloatRelative;
exports.optionalArrayConverters = optionalArrayConverters;
exports.intToPlusMinus = intToPlusMinus;
exports.floatToPercentageString = floatToPercentageString;
exports.createPitch = createPitch;
exports.octave = octave;
exports.pitchWithFirstNote = pitchWithFirstNote;
exports.pitch = pitch;
exports.gain = gain;
exports.pan = pan;
exports.chance = chance;
exports.length = length;
exports.filter = filter;
/* octave Not a pure module */
