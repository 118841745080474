// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Slider$ReasonmlSequencer = require("./Slider.bs.js");
var Timing$ReasonmlSequencer = require("./Timing.bs.js");
var SynthValues$ReasonmlSequencer = require("./SynthValues.bs.js");
var LoopProgress$ReasonmlSequencer = require("./LoopProgress.bs.js");
var SynthInstance$ReasonmlSequencer = require("./SynthInstance.bs.js");

var options = $$Array.init(64, (function (i) {
        return [
                String(i + 1 | 0),
                i + 1 | 0
              ];
      }));

function Track(Props) {
  var id = Props.id;
  var label = Props.label;
  var valueConverter = Props.valueConverter;
  var synthInstance = Props.synthInstance;
  var editMode = Props.editMode;
  var dispatch = Props.dispatch;
  var viewMode;
  if (typeof editMode === "number") {
    viewMode = /* Inactive */0;
  } else if (editMode.TAG === /* Preview */0) {
    var preview = editMode._0;
    viewMode = preview.id === id ? /* Preview */({
          _0: preview.valuesBeforeEdit,
          _1: preview.index
        }) : /* Deactive */1;
  } else {
    viewMode = editMode._0.id === id ? /* Active */2 : /* Deactive */1;
  }
  var timing = SynthInstance$ReasonmlSequencer.timing(synthInstance);
  var values = SynthInstance$ReasonmlSequencer.values(synthInstance);
  var loopLength = SynthInstance$ReasonmlSequencer.loopLength(synthInstance);
  var onAction = React.useCallback((function (update) {
          return function (action) {
            return Curry._1(dispatch, {
                        TAG: /* TrackEditMode */10,
                        _0: id,
                        _1: update,
                        _2: action
                      });
          };
        }), [
        id,
        values
      ]);
  var onSetLength = React.useCallback((function (index) {
          return Curry._1(dispatch, {
                      TAG: /* SetLoopLength */4,
                      _0: id,
                      _1: index
                    });
        }), [id]);
  return React.createElement("div", {
              className: "flex items-center"
            }, React.createElement("p", {
                  className: "ma0 w4 flex-none"
                }, label), React.createElement("div", {
                  className: "w1 flex-none"
                }), React.createElement("select", {
                  value: String(Timing$ReasonmlSequencer.subTicks(timing)),
                  onChange: (function ($$event) {
                      var value = Caml_format.caml_int_of_string($$event.target.value);
                      return Curry._1(dispatch, {
                                  TAG: /* SetSubTicks */6,
                                  _0: id,
                                  _1: value
                                });
                    })
                }, $$Array.map((function (param) {
                        var label = param[0];
                        return React.createElement("option", {
                                    key: label,
                                    value: label
                                  }, label);
                      }), options)), React.createElement("div", {
                  className: "w1 flex-none"
                }), React.createElement("div", {
                  className: "flex-none"
                }, React.createElement(Slider$ReasonmlSequencer.make, {
                      cellSize: 48,
                      viewMode: viewMode,
                      mapValues: valueConverter.mapValues,
                      getValueAt: valueConverter.getValueAt,
                      values: values,
                      disabledIndex: loopLength,
                      onAction: onAction,
                      onSetLength: onSetLength
                    }), React.createElement(LoopProgress$ReasonmlSequencer.make, {
                      cellSize: 48,
                      highlightedIndex: Timing$ReasonmlSequencer.index(timing),
                      disabledIndex: loopLength,
                      length: SynthValues$ReasonmlSequencer.length(values),
                      onSetLength: onSetLength
                    })), React.createElement("div", {
                  className: "w1 flex-none"
                }), React.createElement("div", {
                  className: "flex flex-none f6"
                }, React.createElement("button", {
                      className: "flex-none h2",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, {
                                      TAG: /* RandomiseAbsolute */0,
                                      _0: id
                                    });
                        })
                    }, "Random Absolute"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("button", {
                      className: "flex-none h2",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, {
                                      TAG: /* RandomiseRelative */1,
                                      _0: id
                                    });
                        })
                    }, "Random Relative"), React.createElement("span", {
                      className: "db w1 flex-none"
                    }), React.createElement("button", {
                      className: "flex-none h2",
                      onClick: (function (_event) {
                          return Curry._1(dispatch, {
                                      TAG: /* Reset */2,
                                      _0: id
                                    });
                        })
                    }, "Reset")));
}

var make = Track;

exports.options = options;
exports.make = make;
/* options Not a pure module */
