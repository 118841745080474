// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");

var majorScale = [
  /* Whole */0,
  /* Whole */0,
  /* Half */1,
  /* Whole */0,
  /* Whole */0,
  /* Whole */0,
  /* Half */1
];

function generateScale(majorScaleOffset) {
  var result = Caml_array.make(majorScale.length, 0);
  for(var i = 1 ,i_finish = result.length; i < i_finish; ++i){
    var value = Caml_array.get(majorScale, Caml_int32.mod_((i - 1 | 0) + majorScaleOffset | 0, majorScale.length));
    var increment = value ? 1 : 2;
    Caml_array.set(result, i, Caml_array.get(result, i - 1 | 0) + increment | 0);
  }
  return result;
}

var scaleMajor = generateScale(0);

var scaleDorian = generateScale(1);

var scalePhrygian = generateScale(2);

var scaleLydian = generateScale(3);

var scaleMixolydian = generateScale(4);

var scaleMinor = generateScale(5);

var scaleLocrian = generateScale(6);

var scales = [
  [
    "Major",
    scaleMajor
  ],
  [
    "Minor",
    scaleMinor
  ],
  [
    "Dorian",
    scaleDorian
  ],
  [
    "Phrygian",
    scalePhrygian
  ],
  [
    "Lydian",
    scaleLydian
  ],
  [
    "Mixolydian",
    scaleMixolydian
  ],
  [
    "Locrian",
    scaleLocrian
  ]
];

exports.majorScale = majorScale;
exports.generateScale = generateScale;
exports.scaleMajor = scaleMajor;
exports.scaleDorian = scaleDorian;
exports.scalePhrygian = scalePhrygian;
exports.scaleLydian = scaleLydian;
exports.scaleMixolydian = scaleMixolydian;
exports.scaleMinor = scaleMinor;
exports.scaleLocrian = scaleLocrian;
exports.scales = scales;
/* scaleMajor Not a pure module */
